
import WidgetFactory from '@/models/Charts/widgetFactory';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import { CutStatesIntradayData } from '@/models/Charts/chartsData';
import CutStatesIntradayGenerator from '@/models/Charts/cutStatesIntradayGenerator';
import { Device } from '@/models/device';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DetailedViewEnum } from '@/models/enums/WidgetEnum';
import { isEmpty } from '@/utils/misc';
import { EChartsOption } from 'echarts';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { devicesService } from '@/services/devices.service';
import { tenantsService } from '@/services/tenants.service';

@Component({
  methods: { isEmpty },
})
export default class CutStatesIntraday extends Vue {
  @Prop()
  private selectedDeviceIds!: string[];

  @Prop()
  private selectedShifts!: number[];

  @Prop()
  private onClose?: () => void;

  private data: Record<string, CutStatesIntradayData[]> = {};
  private options: any[] = [];
  private generator: ChartGenerator<CutStatesIntradayData[]> = WidgetFactory.createGenerator(
    DetailedViewEnum.CutStatesIntraday,
    this.tenantIdDh,
  )!;
  private omittedStates: string[] = [];

  private modalOpen = true;
  private loading = false;

  private noData = false;

  private async mounted() {
    await this.update();
  }

  private async update() {
    this.loading = true;
    this.noData = false;
    this.options = new Array(this.deviceIds.length);
    Promise.all(
      this.deviceIds.map(async (deviceid) => {
        const data = await this.generator.getData(
          [deviceid],
          this.selectedShifts,
          FilterTimeSpanEnum.Day,
        );
        if (data?.length) {
          this.data[deviceid] = data;
          return this.updateOptions(deviceid);
        } else {
          this.noData = true;
        }
      }),
    )
      .then((options) => (this.options = options))
      .finally(() => (this.loading = false));
  }

  private updateOptions(deviceId: string): EChartsOption {
    const data = this.data[deviceId] === null ? [] : this.data[deviceId];
    const params = {
      device: this.getDeviceName(deviceId),
      omittedStates: this.omittedStates,
    };
    return this.generator.updateOptions(data, params);
  }

  private getDeviceName(deviceId: string): string {
    return devicesService.store.findByStringId(deviceId)!.name;
  }

  private get deviceIds(): string[] {
    if (isEmpty(this.selectedDeviceIds)) {
      return devicesService.store.cutDevicesIds();
    }
    return this.selectedDeviceIds;
  }

  private onLegendChange({ name }: any) {
    if (this.omittedStates.includes(name)) {
      this.omittedStates = [...this.omittedStates.filter((state) => state !== name)];
    } else {
      this.omittedStates = [...this.omittedStates, name];
    }
    this.options = this.deviceIds.map((deviceId) => this.updateOptions(deviceId));
  }

  private get legendOption() {
    const generator = WidgetFactory.createGenerator(
      DetailedViewEnum.CutStatesIntraday,
      this.tenantIdDh,
    )! as CutStatesIntradayGenerator;
    return generator.updateLegend();
  }

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }

  private beforeDestroy() {
    this.generator.abort();
  }

  private get tenantIdDh(): number {
    return tenantsService.store.currentIdDh()!;
  }
}
